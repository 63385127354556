import React, { useEffect, useState } from 'react';

import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Box from '@mui/material/Box';
import Navbar from './Navbar';
import { Status, loadingGif, stripePK, StatusText } from './utils';

const stripePromise = loadStripe(stripePK);

const OrderConfirmation = (props) => {
  return (
    <>
      <h1>YOUR REQUEST WAS SUCCESSFULLY SUBMITTED!</h1>
      <p>Use this <a href={props.trackingLink} target='_blank'>link</a> to track your delivery progress.</p>
      <p>Questions or feedback? email <a href='mailto:support@milkywaydelivery.com'>support@milkywaydelivery.com.</a></p>
    </>
  );
};

const OrderError = (props) => {
  return (
    <>
      <h1>There was an error processing your delivery</h1>
      <p>Message: {props.statusMessage}</p>
      <p>Questions or feedback? email <a href='mailto:support@milkywaydelivery.com'>support@milkywaydelivery.com</a></p>
    </>
  );
};

const PaymentStatus = (props) => {
  const stripe = useStripe();
  const [status, setStatus] = useState(Status.SUCCESS);
  const [statusMessage, setStatusMessage] = useState('');
  const [deliveryInfo, setDeliveryInfo] = useState();

  const updateStatus = (status, statusMessage) => {
    setStatus(status);
    setStatusMessage(statusMessage);
  }

  // Setup delivery info
  useEffect(() => {
    if (!stripe || !props.clientSecret) {
      updateStatus(Status.GENERAL_ERROR, 
        !props.clientSecret ? 'order does not exist' : 'stripe failed to load');
      return;
    }

    updateStatus(Status.SUCCESS, loadingGif);
    stripe.retrievePaymentIntent(props.clientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            updateStatus(Status.SUCCESS, 'Payment succeeded!');
            break;
          case "processing":
            updateStatus(Status.SUCCESS, 'Your payment is processing.');
            break;
          case "requires_payment_method":
            updateStatus(Status.GENERAL_ERROR, 'Your payment was not successful, please try again');
            break;
          default:
            updateStatus(Status.INTERNAL_ERROR, "Something went wrong.");
            break;
        }
      })
      .catch(err => {
        updateStatus(Status.INTERNAL_ERROR, "Something went wrong.");
      });
  }, [stripe]);

  return (
    <Box className='status-message'>
      {status === Status.SUCCESS && (
        <OrderConfirmation 
          trackingLink={`/delivery-tracking?jobId=${props.jobId}`}
        />
      )}
      {status !== Status.SUCCESS && (
        <OrderError
          statusMessage={statusMessage}
        />
      )}
    </Box>
  );
};

const ConfirmationPage = (props) => {
  const params = new URLSearchParams(window.location.search);
  const clientSecret = params.get('payment_intent_client_secret');
  const jobId = params.get('jobId');

  const options = {
    clientSecret,
  };

  return (
    <div className='confirmation-page'>
      <Navbar />
      <Elements stripe={stripePromise} options={options}>
        <PaymentStatus clientSecret={clientSecret} jobId={jobId} />
      </Elements>
    </div>
  );
};

export default ConfirmationPage;
export { OrderConfirmation }
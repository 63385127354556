import React, { useState, useEffect } from 'react';
import { formatSnakeCase, formatCreatedAt } from './utils';

export const DeliveryTrackingBox = (props) => {
    return (
        <div className='tracking-box-wrapper'>
            <div className='tracking-box'>
                {props.elements && props.elements.map(element => (
                    <div className="tracking-box-element">
                        <p className='tracking-box-title'>{element.label} </p>
                        <p className='tracking-box-text'> {element.value} </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export const DeliveryProof = (props) => {
    return props.proof && (
        <div className='tracking-box-wrapper'>
            <div className='tracking-box'>
                <p className='tracking-box-title'>Proof of Delivery:</p>
                <img
                    className='proof-of-delivery'
                    src={props.proof[0].url}
                />
            </div>
        </div>
    );
};

export const DeliveryTimeline = (props) => {
    return (
        <div className='delivery-timeline'>
            {props.steps && props.steps.map((step, i) => (
                <div className='delivery-status' style={{ display: "inline-flex" }}>
                    <div className='delivery-timeline-step'>
                        <p className="delivery-timeline-step-text"> {formatSnakeCase(step.status)} </p>
                        <p className="delivery-timeline-step-text"> {formatCreatedAt(step.createdAt)} </p>
                    </div>
                    {i != props.steps.length - 1 && (<p className='delivery-timeline-step-arrow'>→</p>)}
                </div>
            ))}
        </div>
    );
};
// import React, { useState, useEffect, useRef } from 'react';
// import mapStyle from './MapStyle';

// const width = window.innerWidth;
// const height = window.innerHeight;

// let directionsService;
// let directionsRenderer;

// const getRootElementFontSize = () => {
//     return parseFloat(
//         getComputedStyle(
//             document.documentElement
//         ).fontSize
//     );
// }

// const convertRem = (value) => {
//     return value * getRootElementFontSize();
// }

// const Marker = (options) => {
//     const [marker, setMarker] = useState();

//     useEffect(() => {
//         if (!marker) {
//             setMarker(new window.google.maps.Marker());
//         }

//         // remove marker from map on unmount
//         return () => {
//             if (marker) {
//                 marker.setMap(null);
//             }
//         };
//     }, [marker]);

//     useEffect(() => {
//         if (marker) {
//             marker.setOptions(options);
//         }
//     }, [marker, options]);

//     return null;
// };

// const Map = ({ onClick, onIdle, children, style, pickup, dropoff, ...options }) => {
//     const ref = useRef();
//     const directionPoints = useRef({
//         pickup: undefined,
//         dropoff: undefined,
//     });
//     const [map, setMap] = useState();

//     const initDirections = () => {
//         if (!directionsService && window.google) {
//             directionsService = new window.google.maps.DirectionsService();
//         }
//         if (!directionsRenderer && window.google) {
//             directionsRenderer = new window.google.maps.DirectionsRenderer();
//         }

//         return directionsService && directionsRenderer;
//     };

//     // add map hook
//     useEffect(() => {
//         if (ref.current && !map) {
//             setMap(new window.google.maps.Map(ref.current, { styles: mapStyle }));
//         }
//     }, [ref, map]);

//     useEffect(() => {
//         if (!map || !pickup || !dropoff)
//             return;

//         initDirections();

//         console.log('Geocoding');

//         // Make sure we don't make excessive requests to directions api
//         if (pickup === directionPoints.current.pickup &&
//             dropoff === directionPoints.current.dropoff)
//             return;

//         if (pickup != directionPoints.current.pickup)
//             directionPoints.current.pickup = pickup;

//         if (dropoff != directionPoints.current.dropoff)
//             directionPoints.current.dropoff = dropoff;

//         // Make directions request
//         const request = {
//             origin: directionPoints.current.pickup,
//             destination: directionPoints.current.dropoff,
//             travelMode: 'DRIVING',
//         };

//         directionsRenderer.setMap(map);
//         directionsService.route(request, (result, status) => {
//             if (status === 'OK') {
//                 directionsRenderer.setDirections(result);
//             }
//         });
//     }, [pickup, dropoff, map]);

//     return (
//         <>
//             <div ref={ref} style={style} />
//             {React.Children.map(children, (child) => {
//                 return React.cloneElement(child, { map });
//             })}
//         </>
//     );
// }

// const RequestMap = (props) => {
//     return (
//         <Map
//             center={{
//                 lat: 34.0552,
//                 lng: -118.2437,
//             }}
//             zoom={11}
//             pickup={props.pickupAddress}
//             dropoff={props.dropoffAddress}
//             style={{ width: width - convertRem(27), height: height - convertRem(5) }}
//         >
//             {/* <Marker
//                 position={new window.google.maps.LatLng({lat: 34, lng: -118})}
//                 title="test"
//             /> */}
//         </Map>
//     );
// }

// export default RequestMap;


import React, { useEffect } from 'react';

import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { Wrapper, Status } from '@googlemaps/react-wrapper';

import Box from '@mui/material/Box';
import mapStyle from './MapStyle';

const width = window.innerWidth;
const height = window.innerHeight;

let geocoder;
let directionsService;
let directionsRenderer;

const getRootElementFontSize = () => {
    return parseFloat(
        getComputedStyle(
            document.documentElement
        ).fontSize
    );
}

const convertRem = (value) => {
    return value * getRootElementFontSize();
}

const render = (status) => {
    return <h1>{status}</h1>;
}

const Marker = (options) => {
    const [marker, setMarker] = React.useState();

    React.useEffect(() => {
        if (!marker) {
            setMarker(new window.google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);
    React.useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);
    return null;
};

const Map = ({ onClick, onIdle, children, style, pickup, dropoff, ...options }) => {
    const ref = React.useRef(null);
    const directionPoints = React.useRef({
        pickup: undefined,
        dropoff: undefined,
    });
    const [map, setMap] = React.useState();

    // add map hook
    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, { styles: mapStyle }));
        }
    }, [ref, map]);

    // component options hook
    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    // event hooks
    React.useEffect(() => {
        if (map) {
            ['click', 'idle'].forEach((eventName) => {
                window.google.maps.event.clearListeners(map, eventName);
            });

            if (onIdle) {
                map.addListener('idle', () => onIdle(map));
            }
        }
    }, ([map, onClick, onIdle]));

    React.useEffect(() => {
        if (!directionsService && window.google) {
            directionsService = new window.google.maps.DirectionsService();
        }
        if (!directionsRenderer && window.google) {
            directionsRenderer = new window.google.maps.DirectionsRenderer();
        }

        if (pickup != directionPoints.current.pickup)
            directionPoints.current.pickup = pickup;

        if (dropoff != directionPoints.current.dropoff)
            directionPoints.current.dropoff = dropoff;

        if (!directionPoints.current.pickup || !directionPoints.current.dropoff)
            return;

        // Make directions request
        const request = {
            origin: directionPoints.current.pickup,
            destination: directionPoints.current.dropoff,
            travelMode: 'DRIVING',
        };

        directionsRenderer.setMap(map);
        directionsService.route(request, (result, status) => {
            options.updateField('distance', 
                result.routes[0].legs[0].distance.value,
            );
            if (status === 'OK') {
                directionsRenderer.setDirections(result);
            }
        });

    }, [pickup, dropoff]);

    return (
        <>
            <div ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
}

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
    if (
        isLatLngLiteral(a) ||
        a instanceof window.google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof window.google.maps.LatLng
    ) {
        return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
    }
    return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }
    return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const MapWrapper = (props) => {
    const [zoom, setZoom] = React.useState(11);
    const [center, setCenter] = React.useState({
        lat: 34.0552,
        lng: -118.2437,
    });
    const [pickupCoords, setPickupCoords] = React.useState();
    const [dropoffCoords, setDropoffCoords] = React.useState();

    // Run whenever map is moved
    const onIdle = (m) => {
        setZoom(m.getZoom());
        setCenter(m.getCenter().toJSON());
    }

    React.useEffect(() => {
        if (!geocoder && window.google)
            geocoder = new window.google.maps.Geocoder();
        if (!geocoder)
            return undefined;

        const placeId = props.pickupAddressPlaceId;
        if (placeId === undefined)
            return;

        geocoder.geocode({ 'placeId': placeId }, (results, status) => {
            if (results) {
                const position = results[0].geometry.location;
                setPickupCoords(new window.google.maps.LatLng({
                    lat: position.lat(), lng: position.lng(),
                }));
            }
        });
    }, [props.pickupAddressPlaceId]);

    React.useEffect(() => {
        if (!geocoder && window.google)
            geocoder = new window.google.maps.Geocoder();
        if (!geocoder)
            return undefined;

        const placeId = props.dropoffAddressPlaceId;
        if (placeId === undefined)
            return;

        geocoder.geocode({ 'placeId': placeId }, (results, status) => {
            if (results) {
                const position = results[0].geometry.location;
                setDropoffCoords(new window.google.maps.LatLng({
                    lat: position.lat(), lng: position.lng(),
                }));
            }
        });
    }, [props.dropoffAddressPlaceId]);

    return (
        <Box className='map-wrapper'>
            <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} render={render} libraries={['places, directions']}>
                <Map
                    center={center}
                    onIdle={onIdle}
                    zoom={zoom}
                    pickup={pickupCoords}
                    dropoff={dropoffCoords}
                    updateField={props.updateField}
                    style={{ width: width - convertRem(27), height: height - convertRem(5) }}
                >
                    {/* {props.pickupAddressPlaceId && (
            <Marker
              position={pickupCoords}
              title="pickup"
              // opacity={0.1}
            />
          )}
          {props.dropoffAddressPlaceId && (
            <Marker
              position={dropoffCoords}
              title="dropoff"
              // opacity={0.1}
            />
          )} */}
                </Map>
            </Wrapper>
        </Box>
    );
}

export default MapWrapper;

import React, { useState, useEffect } from 'react';
import DeliveryMap from './DeliveryMap';
import Navbar from './Navbar'

import { formatStatusFields, formatETA, formatCreatedAt, formatSnakeCase, MapLoading } from './utils';
import { DeliveryTrackingBox, DeliveryProof, DeliveryTimeline } from './DeliveryTrackingBoxes'

import { Wrapper } from '@googlemaps/react-wrapper';

const DeliveryTrackingPage = () => {
    const [jobId, setJobId] = useState(new URLSearchParams(window.location.search).get('jobId'));
    const [valid, setValid] = useState(false);

    // Stores pickup and dropoff locations (set once)
    // Converting addresses costs money, so we only want it to happen once
    const [deliveryData, setDeliveryData] = useState({
        pickupAddress: '',
        dropoffAddress: '',
    });

    // Stores current delivery status (changes)
    const [deliveryStatus, setDeliveryStatus] = useState({
        driver: '',
        driverLocation: '',
        driverStatus: '',
        driverNumber: '',
        eta: '',
    });

    // Stores current delivery step
    const [deliveryStep, setDeliveryStep] = useState({
        assignedDriver: '12:00pm',
        pickupEnroute: '12:34pm',
        pickupComplete: '-',
        dropoffEnroute: '-',
        dropoffArrived: '-',
        dropoffCompleted: '-'
    });

    const updateDeliveryStatus = async (first = false) => {
        let result;

        try {
            const response = await fetch(process.env.REACT_APP_DELIVERY_STATUS_ENDPOINT + jobId);
            setValid(response.status === 200);
            result = await response.json();
        } catch (err) {
            console.log(err);
            setValid(false);
        }

        if (first) {
            setDeliveryData({
                pickupAddress: result.pickupAddress,
                dropoffAddress: result.dropoffAddress,
            });
        }

        result = formatStatusFields(result);

        setDeliveryStatus({
            driverName: result.courierName,
            driverLocation: result.driverLocation,
            driverStatus: result.status,
            driverNumber: result.courierPhoneNumber,
            eta: result.dropoffEta,
            statusHistory: result.statusHistory,
            proofOfDelivery: result.proofOfDelivery,
        });
    }

    useEffect(() => {
        // Get initial delivery data
        updateDeliveryStatus(true);

        // Get additional update every 30 seconds
        const interval = setInterval(() => {
            updateDeliveryStatus();
        }, 3000);

        // This will be run when the component is destroyed
        return () => clearInterval(interval);
    }, []);

    if (!valid)
        return <p>Loading</p>

    return (
        <div id='deliverytracking-wrapper'>
            <Navbar />
            <div id='deliverytracking-map-wrapper'>
                <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} render={MapLoading} libraries={['places, directions']}>
                    <DeliveryMap
                        pickupAddress={deliveryData.pickupAddress}
                        dropoffAddress={deliveryData.dropoffAddress}
                        driverLocation={deliveryStatus.driverLocation}
                    />
                </Wrapper>
            </div>
            <div id="tracking-info">
                <DeliveryTrackingBox
                    elements={[
                        { label: 'Driver: ', value: deliveryStatus.driverName || 'Not Yet Assigned' },
                        { label: 'Number: ', value: deliveryStatus.driverNumber || 'Not Yet Assigned' },
                        { label: 'ETA: ', value: formatETA(deliveryStatus.eta) },
                    ]}
                />
                <DeliveryTrackingBox
                    elements={[
                        { label: 'Status: ', value: formatSnakeCase(deliveryStatus.driverStatus) },
                        { label: 'Pickup: ', value: deliveryData.pickupAddress },
                        { label: 'Dropoff: ', value: deliveryData.dropoffAddress },
                    ]}
                />
                <DeliveryProof
                    proof={deliveryStatus.proofOfDelivery}
                />
            </div>
            <div className='Delivery-Timeline-Container'>
                <DeliveryTimeline
                    steps={deliveryStatus.statusHistory}
                />
            </div>
            {/* <div id="deliverytracking-page-right-side"> */}
            {/* <DeliveryTrackingStep title="Assigned Driver" time={deliveryStep.assignedDriver} />
                    <DeliveryTrackingStep title="Pickup Enroute" time={deliveryStep.pickupEnroute} />
                    <DeliveryTrackingStep title="Pickup Complete" time={deliveryStep.pickupComplete} />
                    <DeliveryTrackingStep title="Dropoff Enroute" time={deliveryStep.dropoffEnroute} />
                    <DeliveryTrackingStep title="Dropoff Arrived" time={deliveryStep.dropoffArrived} />
                    <DeliveryTrackingStep title="Dropoff Complete" time={deliveryStep.dropoffCompleted} /> */}
            {/* </div> */}
        </div>
    );
}

export default DeliveryTrackingPage
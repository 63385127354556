import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from 'react-router-dom';

import './index.css';
import App from './App';
import Login from './Login';
import Register from './Register';
import ConfirmationPage from './ConfirmationPage';
import DeliveryTrackingPage from './DeliveryTrackingPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/delivery-confirmation',
    element: <ConfirmationPage />,
  },
  {
    path: '/delivery-tracking',
    element: <DeliveryTrackingPage />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


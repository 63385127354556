import React, { useState, useEffect } from 'react';

const Register = (props) => {
  return (
    <div className='register'>
      <p>This is the Registration Page</p>
    </div>
  );
}

export default Register;

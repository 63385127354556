import React, { useState, useEffect } from 'react';

import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripePK } from './utils';

import Button from '@mui/material/Button';

const stripePromise = loadStripe(stripePK);

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        // Prevent default form submission
        event.preventDefault();

        if (!stripe || !elements) {
            // Make sure that stripe has loaded
            return;
        }

        // setIsLoading(true);

        console.log(props.jobInfo.id);
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: process.env.REACT_APP_DELIVERY_CONFIRMATION_ENDPOINT + `?jobId=${props.jobInfo.id}`,
            },
        });

        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message);
        } else {
            setMessage('An unexpected error occurred');
        }
    }

    // setIsLoading(false);

    return (
        // <form id="payment-form" onSubmit={handleSubmit}>
        <form id="payment-form">
            <PaymentElement id="payment-element" />
            <small className='stripe-stamp'>
                <p>Powered by</p>
                <img src={process.env.PUBLIC_URL + '/images/stripe-logo.png'} />
            </small><br />
            <Button variant='contained' disabled={!stripe || !elements} onClick={handleSubmit} id="submit">
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"></div> : "Request Delivery"}
                </span>
            </Button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}

const StripeWrapper = (props) => {
    const options = {
        clientSecret: props.stripeClientSecret,
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm jobInfo={props.jobInfo} />
        </Elements>
    );
};

export default StripeWrapper;
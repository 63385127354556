import React from 'react';

const ICON_WIDTH = 15;

class Navbar extends React.Component {
  render() {
    return (
      <nav className='navbar'>
        <a href='/' className='home-button navbar-link'>
          <img
            src={process.env.PUBLIC_URL + '/images/milkywaylogo.png'}
            className='logo' />
          {/* <p>Milkyway Delivery</p> */}
        </a>
        <div className='navbar-menu'>
          <ul>
            {/* <li>
              <a href='#' className='navbar-link'>
                <img src={process.env.PUBLIC_URL + '/images/plus.svg'} width={ICON_WIDTH}></img>
                <p>New Request</p>
              </a>
            </li>
            <li>
              <a href='#' className='navbar-link'>
                <img src={process.env.PUBLIC_URL + '/images/all.svg'} width={ICON_WIDTH}></img>
                <p>All Deliveries</p>
              </a>
            </li> */}
            <li>
              <a href='https://milkywaydelivery.com' className='menu-button navbar-link'>
                <img src={process.env.PUBLIC_URL + '/images/menu.svg'} width={ICON_WIDTH} ></img>
                {/* <p>Menu</p> */}
              </a>
            </li>
          </ul>
        </div>

      </nav>
    );
  }
}

export default Navbar;

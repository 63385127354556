import React from 'react';

import {checkMissingFields, getPriceInDollars, loadingGif, processQuotes, Status} from './utils';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import MapWrapper from './Map';
// import { Wrapper } from '@googlemaps/react-wrapper';
import {MILKYWAY_FEE, formatDisplayTime, MapLoading} from './utils';
import dayjs from 'dayjs'
import { touchRippleClasses } from '@mui/material';

const formatTime = (time) => {
    return time.format('YYYY-MM-DDTHH:mm:00[Z]');
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            packageDeliveryMode: 'now',
            pickupTime: dayjs(),
            dropoffTime: dayjs(),
            quoteIndex: 0,
            promoCode: '',
        };

        this.updateField = this.updateField.bind(this);
        this.getQuotes = this.getQuotes.bind(this);
        this.requestDelivery = this.requestDelivery.bind(this);
    }

    updateStatus(status, statusMessage) {
        this.setState({
            status: status,
            statusMessage: statusMessage,
        });
    }

    updateField(field, value) {
        let temp = {};
        temp[field] = value;
        this.setState(temp);
    }

    getQuotes() {
        // Check validity of data 
        let name, first, last;
        try {
            name = this.state.dropoffName.split(' ');
            first = name[0];
            last = name[1];
        } catch (err) {
            this.updateStatus(Status.GENERAL_ERROR, 'Invalid name format (ex. John Smith)');
            return;
        }

        if (isNaN(Number(this.state.packageValue)))
            return this.updateStatus(Status.GENERAL_ERROR, 'Package value must be a number');

        let deliveryInfo = {
            // Required
            pickupAddress: this.state.pickupAddress,
            dropoffAddress: this.state.dropoffAddress,
            dropoffPhoneNumber: this.state.dropoffPhoneNumber,
            dropoffFirstName: first,
            dropoffLastName: last,
            packageValue: this.state.packageValue,
            packageDeliveryMode: this.state.packageDeliveryMode,
            dropoffInstructions: this.state.dropoffInstructions,
            pickupInstructions: this.state.pickupInstructions,

            // Optional
            pickupTime: formatTime(this.state.pickupTime),
            dropoffTime: formatTime(this.state.dropoffTime),
            totalPrice: this.state.totalPrice,
            distance: this.state.distance,
        };
        // console.log(deliveryInfo);

        const missing = checkMissingFields(deliveryInfo);
        if (missing) {
            this.updateStatus(Status.GENERAL_ERROR, `${missing} is required`);
            return;
        }

        this.updateStatus(Status.SUCCESS, loadingGif);

        //fetch('/api/delivery/createJob', {
        fetch(process.env.REACT_APP_CREATE_JOB_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(deliveryInfo),
        })
            .then(res => res.json())
            .then(res => this.displayQuotes(res))
            .catch(err => {
                this.updateStatus(Status.INTERNAL_ERROR, 'Failed to get quotes');
            });
    }

    displayQuotes(res) {
        document.getElementById('sidebar').scrollTo(0, 1000);
        if (res.error) {
            let message;
            switch (res.error.code) {
                case 'INVALID_ADDRESS':
                    message = 'Address\' must be exact street address';
                    break;
                case 'INVALID_PHONE_NUMBER':
                    message = res.error.message;
                    break;
                case 'INVALID_DISTANCE':
                    message = res.error.message;
                    break;
                default:
                    message = 'Failed to get quotes';
            }

            this.updateStatus(Status.GENERAL_ERROR, message);
            return;
        }

        let initialQuotes = res["jobConfigurations"][0]["tasks"][0]["quotes"];
        if (initialQuotes.length < 1) {
            this.updateStatus(Status.GENERAL_ERROR, 'We apologize, no drivers are currently available for that route.');
            return;
        }

        const jobInfo = {
            quotes: processQuotes(initialQuotes), // stores unique filtered quotes
            id: res['id'],
        }
        // console.log(jobInfo);

        // Add additional formatted fields for eta and price
        for (let i = 0; i < jobInfo.quotes.length; i++) {
            jobInfo.quotes[i].eta = formatDisplayTime(jobInfo.quotes[i]);
            jobInfo.quotes[i].price = getPriceInDollars(
                jobInfo.quotes[i].priceCents +
                Number(process.env.REACT_APP_DRIVER_TIP) +
                Number(process.env.REACT_APP_DRIVER_FEE)
            );
            jobInfo.quotes[i].totalPrice = Math.round((jobInfo.quotes[i].price + MILKYWAY_FEE) * 100) / 100;
        }

        this.setState({
            jobInfo: jobInfo,
            status: Status.SUCCESS,
            statusMessage: '',
        });
    }

    requestDelivery() {
        const requestInfo = {
            quoteIndex: this.state.quoteIndex,
            quoteId: this.state.quoteId,
            jobId: this.state.jobInfo.id,
            requestedPrice: this.state.requestedPrice,
            promoCode: this.state.promoCode
        }

        this.updateStatus(Status.SUCCESS, loadingGif);

        //fetch('/api/delivery/selectQuote', {
        fetch(process.env.REACT_APP_SELECT_QUOTE_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestInfo),
        })
            .then(res => res.json())
            .then(res => {
                    if (res.error || res.status >= 400) {
                        this.updateStatus(Status.GENERAL_ERROR, res.message || 'You must select a price');
                        return;
                    }

                    this.setState({
                        stripeClientSecret: res.clientSecret,
                        status: Status.SUCCESS,
                        statusMessage: '',
                    });
                }
            )
            .catch(err => {
                console.error(err.message);
                this.updateStatus(Status.INTERNAL_ERROR, 'Failed to proceed to checkout');
            });
    }

    render() {
        return (
            <div className='app'>
                <Navbar/>
                <div className='app-interface'>
                    <Sidebar
                        updateField={this.updateField}
                        getQuotes={this.getQuotes}
                        requestDelivery={this.requestDelivery}
                        status={this.state.status}
                        statusMessage={this.state.statusMessage}
                        jobInfo={this.state.jobInfo}
                        stripeClientSecret={this.state.stripeClientSecret}

                        pickupTime={this.state.pickupTime}
                        dropoffTime={this.state.dropoffTime}
                        quoteIndex={this.state.quoteIndex}
                        requestedPrice={this.state.requestedPrice}
                        promoCode={this.state.promoCode}

                        pickupAddressPlaceId={this.state.pickupAddressPlaceId}
                        dropoffAddressPlaceId={this.state.dropoffAddressPlaceId}
                        distance={this.state.distance}

                        milkywayFee={MILKYWAY_FEE}
                    />
                    <MapWrapper
                        updateField={this.updateField}
                        pickupAddressPlaceId={this.state.pickupAddressPlaceId}
                        dropoffAddressPlaceId={this.state.dropoffAddressPlaceId}
                    />
                    {/* <Wrapper 
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} 
            render={MapLoading}
            libraries={['places, directions']}
            className='map-wrapper'>
            <RequestMap
              pickupAddress={this.state.pickupAddress}
              dropoffAddress={this.state.dropoffAddress}
            />
          </Wrapper> */}
                </div>
            </div>
        );
    }
}

export default App;

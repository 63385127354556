import React, { useState, useEffect } from 'react';

const Login = (props) => {
  return (
    <div className='login'>
      <p>This is the Login Page</p>
    </div>
  );
}

export default Login;
